@import "styles/styles.scss";
.link {
  @include titleSmallX4(true);
  
  text-decoration: none;

  &:focus,
  &:hover {
    text-decoration: none;
    color: $color-terciary;
  }

  &__largeFont {
    @include titleSmallX2(true);
  }

  &__highlight {
    color: $color-secondary;
  }
}

.badge {
  margin-left: 4px;
}
