@import "styles/styles.scss";
.DropdownList {
  @extend %list-no-style;

  max-height: 0;
  visibility: hidden;
  border: 1px solid $color-primary;
  overflow-x: hidden;
  overflow-y: overlay;
  position: relative;
  background-color: $color-neutral-min;
  z-index: 5;
  width: 110px;

  &__open {
    max-height: 200px;
    visibility: visible;
  }

  &__Item {
    cursor: pointer;
    padding: 14px 20px;
    width: 100%;
    text-align: left;
    -webkit-tap-highlight-color: transparent;
  
    &:focus {
      outline: none;
    }
  
    &:hover,
    &:focus-visible {
      background-color: $color-neutral-light;
    }
  
    &.selected,
    &.selected:hover {
      background-color: $color-primary;
      color: $color-neutral-min;
    }
  }
}