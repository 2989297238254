@import "styles/styles.scss";
.countrySwitcher {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $color-primary-medium;
  color: $color-neutral-min;
  height: 100px;
  width: 100%;

  .countrySwitcherIcon {
    margin: 0 14px 0 20px;
  }

  .countrySwitcherArrow {
    position: absolute;
    right: 20px;
    top: 1px;
    stroke: $color-neutral-min;

    &[data-open="true"] {
      transform: rotate(180deg);
    }
  }

  .countrySwitcherContainer {
    background: transparent;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    padding: 0;
    border: 1px solid $color-neutral-medium;
    cursor: pointer;
    position: relative;
    top: 25%;
    z-index: 1;

    &:focus {
      outline: none;
    }
  }

  .countrySwitcherContent {
    align-items: center;
    background-color: $color-primary-medium;
    display: flex;
    height: 50px;
    position: relative;
    width: 311px;
    text-decoration: none;
    padding: 0;
  }

  .countrySwitcherContentLinkSelected {
    background-color: $color-primary;
    cursor: not-allowed;
  }

  .countrySwitcherValue {
    @include bodyCopySmall;

    color: $color-neutral-min;
    line-height: 48px;
    padding-top: 2px;
  }

  .countryFlag {
    width: 64px;
    height: 50px;
  }
}

.countrySwitcherSelectorWrapper {
  display: none;

  &__open {
    display: block;
  }
}
