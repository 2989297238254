@import "styles/styles.scss";
.flashItem {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0 8px 0 14px;
  height: 100%;
  text-decoration: none;
  width: 100%;

  @include tablet-up {
    padding-right: 14px;
  }
}

.flashItem__link {
  @extend .flashItem !optional;

  height: 100%;
  cursor: pointer;

  &:hover,
  &:focus {
    cursor: pointer;
    text-decoration: none;
  }
}

.flashItem__linkDismissable {
  justify-content: start;

  @include desktop-up {
    justify-content: center;
  }
}
