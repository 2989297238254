@import "styles/styles.scss";
.flashLinkWrapper {
  margin-left: auto;

  @include tablet-up {
    margin-left: 40px;
  }

  .flashLink {
    display: block;
  }

  &__narrowLink {
    margin-left: 0;

    @include tablet-up {
      margin-left: 40px;
    }
  }
}

.flashLink__icon {
  height: 10px;
  transform: rotate(270deg);
  width: 14px;
}
