@import "styles/styles.scss";

.form__error {
  @include bodyCopySmall;

  text-transform: none;
  color: $color-danger;
  margin-top: 8px;
  text-align: center;
}

.form__field {
  width: 100%;

  &.form__field_increasedHeight {
    margin-bottom: 10px;
  }
}
