@import "styles/styles.scss";
$animation-timing: cubic-bezier(0.65, 0.05, 0.36, 1);

@keyframes fade-and-slide-in {
  0% {
    visibility: hidden;
    left: -30px;
    opacity: 0;
  }

  100% {
    opacity: 1;
    left: 0;
    visibility: visible;
  }
}

.MobileMenuWrapper {
  display: block;
  height: 100vh;
  width: 100%;
  position: relative;
  animation-name: fade-and-slide-in;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
  animation-timing-function: $animation-timing;
}

.MobileMenuLinks {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  max-width: 245px;
  padding: 0;

  li {
    @extend %list-no-style;
  }
}

.LineSeparator {
  height: 1px;
  display: block;
  background-color: $color-neutral-medium;
  width: calc(100% - 60px); /** reduce for wrapper padding (30px + 30px) **/
  margin: 42px 0 45px;
}

.SecondaryMenuLinks {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  padding: 0;

  li {
    @extend %list-no-style;
  }

  &__button {
    margin-bottom: 15px;
  } 
}