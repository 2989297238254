@import "styles/styles.scss";
.MobileHeader {
  height: $header-height-regular;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 5;
  padding: 0 0 0 17px;

  @include desktop-up {
    display: none;
  }
}

.MobileHeader__burger {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 13px;
  width: 20px;

  &::after, &::before {
    background-color: var(--header-color-theme);
    content: '';
    height: 1px;
    width: 20px;
    display: block;
    border-radius: 10px;
    transition: background-color 0.3s ease-in;
  }

  &::after {
    margin-top: 5px;
  }

  &::before {
    margin-bottom: 5px;
  }

  span {
    background-color: var(--header-color-theme);
    display: block;
    position: relative;
    height: 1px;
    width: 20px;
    transition: background-color 0.3s ease-in;
  }
}

.MobileHeader__toggleMenu {
  display: flex;
  align-items: center ;
  height: 100%;
  padding: 0 17px 0 13px;
}

.MobileHeader__accountButton {
  display: flex;
  align-items: center ;
  height: 100%;
  padding: 0 13px;

  svg {
    height: 23px;
    width: 23px;

    path {
      fill: var(--header-color-theme);
      transition: fill 0.3s ease-in;
    }
  }
}

.MobileHeader__cartButton {
  display: flex;
  align-items: center ;
  height: 100%;
  padding: 0 13px;
  margin-top: -5px;

  svg {
    height: 24px;
    width: 20px;
  }
}

.MobileDrawer__searchButton {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 13px;

  &_active {
    &::after {
      content: '';
      display: block;
      width: 100%;
      background-color: $color-secondary;
      height: 3px;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }

  svg {
    path, circle {
      stroke: var(--header-color-theme);
      transition: fill 0.3s ease-in;;
    }
  }
}

.MobileDrawer__closeButton {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 10;
}

$transition: all 0.5s ease-in-out;

.MobileDrawer__animation {
  opacity: 0;
  left: 20px;
  transition: $transition;
}

/** Default Header Theme **/
.default {
  .MobileHeader__toggleMenu {
    outline: 1px solid $color-primary;

    span, &::after, &::before {
      background-color: $color-primary;
    }
  }

  .MobileHeader__accountButton {
    svg {
      path {
        fill: $color-primary;
        transition: fill 0.3s ease-in;
      }
    }
  }

  
  .MobileDrawer__searchButton {
    svg {
      path, circle {
        stroke: $color-primary;
        transition: fill 0.3s ease-in;
      }
    }
  }

  .MobileHeader__cartButton {
    svg {
      path {
        fill: $color-primary;
      }
    }
    }
}