@import "styles/styles.scss";
.modal__container {
  background-color: $color-neutral-min;
  align-items: flex-start;
  display: flex;
  justify-content: center;
  height: 100%;
  min-height: 100vh; /* fall-back */
  min-height: stretch;
  width: 100%;
  position: relative;
  overflow-y: auto;
  max-height: 100%;

  @include tablet-up {
    align-items: inherit;
    max-height: 100%;
    height: auto;
    min-height: inherit;
    width: auto;
  }

  &.modal__container_flexStart {
    align-items: flex-start;

    @include tablet-up {
      align-items: center;
    }
  }
}

.modal__closeButton {
  border: none;
  display: block;
  cursor: pointer;
  outline: none;
  position: absolute;
  right: 17px;
  top: 15px;
  z-index: 3;
  background: transparent;
  padding: 0;

  &.modal__closeButton_lightBg {
    height: 42px;
    top: 17px;
    width: 42px;
  }

  svg {
    transition: fill 0.3s $ease-in-out;

    &:hover,
    &:focus {
      fill: $color-secondary;

      :global(g) {
        stroke: $color-secondary;
      }
    }
  }
}
