@import "styles/styles.scss";
.footer {
  @include tablet-up {
    padding-bottom: 63px;
    padding-top: 80px;
  }
}

.footerTitle {
  color: $color-neutral-min;
  margin-bottom: 8px;
  text-align: center;

  @include tablet-up {
    margin-bottom: 16px;
    text-align: left;
  }

  a {
    color: inherit;
  }
}

.formFlex {
  margin-top: 11px;
  padding-left: 14px;
  padding-right: 14px;

  @include tablet-up {
    padding-left: 0;
    padding-right: 20px;
    margin-right: 40px;
  }

  
}

.form__fields form {
  display: flex;
  max-width: 500px;
  margin: 0 auto;

  @include tablet-up {
    margin: 0;
  }

  > :first-child {
    flex: 3;
  }

  button {
    flex: 1;
    height: 50px;
    margin-left: 1px;
  }
}

.form__errors {
  @include bodyCopySmall;

  text-transform: none;
  color: $color-danger;
  margin-top: 8px;
  text-align: center;
}

.footer__paymentOptions {
  min-height: 76px;
  max-width: 340px;
  padding: 0;
  margin: 20px 0 0;

  @include tablet-large-down {
    margin: 20px auto;
  }
}
