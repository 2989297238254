@import "styles/styles.scss";
.buttonIcon {
  display: flex;

  &:first-child {
    margin-right: 15px;
  }

  &:last-child {
    margin-left: 15px;
  }
}
