@import "styles/styles.scss";
.savedFitModalContainer {
  background-color: $color-neutral-min;
  max-width: 780px;
  padding-top: 20px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.savedFitModalWrapper {
  padding: 0 20px;

  @include desktop-up {
    padding: 0 100px;
  }
}

.savedFitModalContainer__buttonWrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 40px;

  button {
    margin-bottom: 20px;
  }

  @include tablet-up {
    > button:first-child {
      margin-right: 10px;
    }

    > button:last-child {
      margin-left: 10px;
    }
  }
}

.savedFitModalContainer__Footer {
  background-color: $color-neutral-light;
  padding: 30px 20px;
  text-align: center;

  &_title {
    @include titleSmallX2;

    margin-bottom: 10px;
  }

  @include desktop-up {
    display: flex;
    align-items: center;
    justify-content: center;

    &_title {
      margin-bottom: 0;
      margin-right: 20px;
    }
  }
}
