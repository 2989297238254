@import "styles/styles.scss";
.link {
  color: $color-primary;
  text-decoration: none;

  &:focus,
  &:hover {
    text-decoration: none;
    color: $color-primary-extra-light;
  }
}

.default {
  @include titleSmallX4(true);
}

.large {
  @include titleSmallX2(true);

  @include desktop-up {
    @include titleSmallX3(true);
  }
}

.primary {
  @include fontFamilySecondary();
}

.secondary {
  @include bodyCopyRegular(true);

}

.badge {
  margin-left: 5px;
}
