@import "styles/styles.scss";
.footerSecondaryNav {
  display: block;
  margin-top: 30px;

  @include desktop-up {
    grid-column: 2;
    display: flex;
    justify-content: flex-end;
    flex-basis: 100%;
  }

  .footerSmallprint {
    color: $color-terciary-extra-light;
    display: block;
    white-space: nowrap;
    text-align: center;
    margin-bottom: 10px;

    @include desktop-up {
      padding-left: 35px;
      margin-bottom: 0;
      text-align: left;
    }

    a {
      @include bodyCopySmall;

      color: $color-neutral-min;
      outline: none;
    }

    &:first-child {
      @extend .footerSmallprint;

      @include tablet-up {
        padding-left: 0;
      }
    }
  }
}
