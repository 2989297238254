@import "styles/styles.scss";

.DesktopHeader__megamenu {
  background-color: $color-neutral-min;
  height: 0;
  position: absolute;
  width: 100vw;
  left: 0;
  right: 0;
  top: 70px;
  z-index: 1;
  display: none;
  transition: all var(--header-transition);
  overflow: hidden;

  @include desktop-up {
    display: flex;

    :global(.megamenu-animation) {
      opacity: 0;
      transition: opacity var(--header-transition);
    }
  }

  &__open:not(.DesktopHeader__megamenu__openActive) {
    height: 370px;

    :global(.megamenu-animation) {
      opacity: 1;
      transition: opacity var(--header-transition);
    }
  }

  &__openActive {
    height: 370px;

    :global(.megamenu-animation) {
      opacity: 1;
      transition: opacity var(--header-transition);
     } 
  }

  &__closeActive {
    height: 370px;
    visibility: hidden;

    :global(.megamenu-animation) {
      opacity: 0;
      transition: opacity var(--header-transition);
    }
  }
}

