@import "styles/styles.scss";
.NewsletterModalWrapper {
  width: 100%;

  @include tablet-up {
    max-width: 900px;
  }
}

.NewsletterModalContent__content {
  background-color: $color-neutral-min;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: $color-primary;
  text-align: center;

  @include tablet-up {
    flex-direction: row-reverse;
  }

  p strong {
    @include bodyCopyBold;
  }
}

.NewsletterModalContent__imageWrapper {
  margin: 0 auto;
  overflow: hidden;
  width: 100%;
  height: 350px;

  @include tablet-up {
    height: auto;
    width: 40%;
  }

  @include desktop-up {
    width: 50%;
  }

  &.placeholder {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: $color-neutral-light;
    }
  }
}

.NewsletterModalContent__image {
  display: block;
  width: 100%;
  height: auto;
  transform: translateY(-100px);

  @include tablet-up {
    width: auto;
    transform: translateX(-50%);
    margin-left: 50%;
    max-width: 450px;
    height: 500px;
  }
}

.NewsletterModalContent__step {
  padding-right: 37px;
  padding-left: 37px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin: 37px auto 0;

  @include tablet-up {
    height: auto;
    margin-top: 0;
    justify-content: center;
    padding-right: 45px;
    padding-left: 45px;
    width: 60%;
  }

  @include desktop-up {
    width: 50%;
    max-width: 100%;
    padding-right: 65px;
    padding-left: 65px;
  }
}

.NewsletterPhoneEmailSubscription__description {
  color: $color-terciary;
  margin-bottom: 10px;
}

.NewsletterPhoneEmailSubscription__content,
.NewsletterModalContent__step {
  > button {
    margin-top: 15px;

    + button {
      margin-top: 20px;
    }
  }

  :global(.confirmationStepButton) {
    margin-top: 35px;
    width: 100%;
  }

  form {
    width: 100%;
    margin-top: 20px;

    button {
      margin-top: 20px;
    }

    + button {
      margin-top: 5px;
    }
  }

  > h3 {
    margin-bottom: 15px;

    + button {
      margin-top: 30px;
    }
  }

  > p {
    margin-bottom: 10px;
  }
}

.PhoneForm {
  .PhoneForm__inputWrapper {
    display: flex;
  }

  .PhoneForm__numberCountry {
    @include bodyCopySmall;

    background-color: $color-neutral-light;
    color: $color-terciary-light;
    display: flex;
    align-items: center;
    border: 1px solid $color-neutral-medium;
    border-right: none;
    padding: 0 15px;
  }

  .PhoneForm__countryPreffix {
    margin-left: 5px;
  }
}

.NewsletterPhoneEmailSubscription__content {
  @include tablet-up {
    margin-top: auto;
  }
}

.NewsletterPhoneEmailSubscription__terms {
  @include bodyCopySmallX2;

  color: $color-terciary-light;
  padding: 20px;
  margin-right: -37px;
  margin-left: -37px;
  margin-top: auto;

  a {
    @include bodyCopySmallX2;
    @include bodyCopyBold;

    color: $color-primary;
  }

  @include tablet-up {
    margin-right: -45px;
    margin-left: -45px;
  }

  @include desktop-up {
    margin-right: -65px;
    margin-left: -65px;
  }
}

p.NewsletterPhoneEmailSubscription__errorMessage {
  @include bodyCopySmall;

  text-transform: none;
  color: $color-danger;
  margin-top: 8px;
}
