@import "styles/styles.scss";
$animation-speed: 1s;

@keyframes loading {
  0%,
  100% {
    opacity: 0.5;
    transform: scale(0.8);
  }

  30%,
  60% {
    opacity: 1;
    transform: scale(1);
  }
}

.loader {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  transition: all 0.3s $ease-in-out;
  width: 100%;
  height: 45px;
  overflow: hidden;
  position: relative;
  margin: 16px 0;

  &.fullHeight {
    height: 100%;
  }

  .loader__loading {
    align-items: center;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(0);
    transition: transform 0.3s $ease-out;
  }

  .loader__dot {
    border-radius: 50%;
    display: block;
    height: 10px;
    margin-left: 5px;
    margin-right: 5px;
    opacity: 0;
    transition: background-color 0.3s $ease-in-out;
    width: 10px;
    background-color: $color-primary;
    animation: loading $animation-speed $ease-in-out infinite;

    &:nth-child(1) {
      animation-delay: initial;
    }

    &:nth-child(2) {
      animation-delay: $animation-speed / 10;
    }

    &:nth-child(3) {
      animation-delay: ($animation-speed / 10) * 2;
    }

    &:nth-child(4) {
      animation-delay: ($animation-speed / 10) * 3;
    }

    &:nth-child(5) {
      animation-delay: ($animation-speed / 10) * 4;
    }
  }

  .loader__tick {
    background-color: $color-secondary;

    &::before,
    &::after {
      background-color: $color-neutral-min;
    }
  }
}
