@import "styles/styles.scss";
.form__input {
  @include bodyCopyRegular(true);

  appearance: none;
  background-color: $color-neutral-min;
  border: 1px solid $color-neutral-medium;
  border-radius: 2px;
  color: $color-terciary;
  height: 50px;
  outline: none;
  padding: 0 18px;
  text-transform: none;
  transition: border-color 0.3s $ease-in-out;
  width: 100%;

  &:focus {
    border-color: $color-terciary;
  }

  &::placeholder {
    color: $color-terciary-extra-light;
    line-height: 40px;
    padding: 5px;
  }

  &.form__input_withButtonRight {
    border-right: none;
  }

  &:global(.form__input--error) {
    border-color: $color-danger;
    color: $color-primary !important;
    text-align: left;
  }

  &.form__input_large {
    height: 50px;
    margin-top: 12px;
  }

  &.form__input_reducedWidth {
    width: unset;
  }
}
