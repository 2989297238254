@import "styles/styles.scss";
.socialLink {
  cursor: pointer;
  display: flex;
  height: 18px;
  outline: none;
  position: relative;
  width: 18px;

  &:hover,
  &:focus {
    :global(svg path) {
      fill: $color-secondary;
    }
  }
}

.socialItem {
  &:not(:first-child) {
    margin-left: 30px;
  }
}
