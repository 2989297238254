@import "styles/styles.scss";

.DesktopHeader {
  height: $header-height-large - 1px;
  z-index: 5;
  display: none;

  @include desktop-up {
    display: flex;
    max-width: $max-width-large;
    margin: 0 auto;
    width: 100%;
  }
}

.DesktopHeader__logo {
  padding-left: 18px;
}

.DesktopHeader__megamenu {
  height: 0;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  z-index: -1;
  display: none;

  @include desktop-up {
    display: flex;
    opacity: 0;
  }

}

.DesktopHeader__fullBackground {
  display: none;

  @include desktop-up {
    visibility: hidden;
    display: block;
    position: fixed;
    background-color: black;
    opacity: 0;
    height: 100vh;
    top: calc(#{$header-height-large} + #{$flashbanner-height-desktop});
    width: 100vw;
    transition: opacity 0.3s ease-out;

    &[data-state="open"] {
      opacity: 0.4;
      transition: opacity 0.3s ease-in;
      visibility: visible;
    }
  }
}

.DesktopHeader__headerLogoInactive {
  &::after {
    bottom: -3px;
    transition: bottom 0.3s ease-in-out;
  }
}