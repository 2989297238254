@import "styles/styles.scss";
.PageError {
  min-height: inherit;
  width: auto;
  background-color: $color-neutral-min;
  text-align: center;

  h1 {
    @include titleSmall;

    padding-top: 60px;
    padding-bottom: 20px;

    @include tablet-up {
      @include titleMedium;
    }
  }

  .PageError__content {
    margin-bottom: 40px;
  }
}
