@import "styles/styles.scss";
%link-block {
  color: $color-primary;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;

  &::after {
    content: "";
    display: block;
    width: 99%;
    height: 1px;
    background-color: $color-primary;
    transition-property: color, text-decoration, background-color;
    transition-duration: 0.3s;
    transition-timing-function: $ease-in-out;
  }

  &:hover,
  &:focus {
    &::after {
      width: 99%;
    }
  }
}

.link {
  @include bodyCopySmall;
  @include bodyCopyBold;

  display: inline-block;
  cursor: pointer;
  transition-property: color, text-decoration;
  transition-duration: 0.3s;
  transition-timing-function: $ease-in-out;

  &:hover,
  &:focus {
    color: $color-primary;
    text-decoration: none !important;

    &::after {
      background: $color-primary;
    }
  }

  &:global(.link--block) {
    @extend %link-block;
  }

  &:global(.link--block-secondary) {
    @extend %link-block;

    color: $color-secondary;

    &::after {
      background: $color-secondary;
    }

    &:hover,
    &:focus {
      color: $color-secondary;

      &::after {
        background: $color-secondary;
      }
    }
  }

  &:global(.link--dark) {
    @extend %link-block;
  }

  &:global(.link--light-grey) {
    color: $color-terciary-light;

    &::after {
      background: $color-terciary-light;
    }

    &:hover,
    &:focus {
      color: $color-terciary;

      &::after {
        background: $color-terciary;
      }
    }
  }

  &:global(.link--light),
  &:global(.link--white) {
    color: $color-neutral-min;

    &::after {
      background: $color-neutral-min;
    }

    &:hover,
    &:focus {
      color: $color-neutral-min;

      &::after {
        background: $color-neutral-min;
      }
    }
  }

  &:global(.link--secondary) {
    color: $color-secondary;

    &::after {
      background: $color-secondary;
    }

    &:hover,
    &:focus {
      color: $color-secondary;

      &::after {
        background: $color-secondary;
      }
    }
  }

  &:global(.link--small) {
    @include bodyCopySmall;
  }

  &:global(.link--regular) {
    @include bodyCopyRegular;
  }

  &:global(.link__byline) {
    @include bodyCopyRegular;

    color: $color-primary;
    outline: none;
    text-transform: unset;
    text-decoration: underline;
    transition-property: color, text-decoration;
    transition-duration: 0.3s;
    transition-timing-function: $ease-in-out;

    &:hover,
    &:focus {
      text-decoration: underline;
      color: $color-secondary;
    }
  }

  &:global(.link--regular-weight) {
    @include bodyCopyDefaults(true);
  }

  &:global(.social__link) {
    background-color: $color-neutral-min;
    border: 1px solid $color-neutral-medium;
    border-radius: 50%;
    height: 52px;
    width: 52px;
    transition: border-color 0.3s $ease-in-out;

    &:hover,
    &:focus {
      border-color: $color-secondary;

      :global(svg) {
        path {
          fill: $color-secondary;
        }
      }
    }
  }

  &:global(.gdpr-banner__policy-link) {
    color: $color-terciary-light;
    text-decoration: underline;
    text-transform: inherit;
  }
}
