@import "styles/styles.scss";
.promoImageWrapper {
  display: block;
  text-decoration: none;
  max-width: 160px;
  min-width: 150px;
  width: 100%;

  @include desktop-up {
    max-width: var(--width);
  }

  &__title {
    @include titleSmallX4(true);

    margin-top: 13px;

    @include desktopX1-up {
      margin-top: 10px;
    }
  }

  &__promoImage {
    background-color: $color-neutral-medium;
    position: relative;
    height: var(--height);
    overflow: hidden;

    img {
      object-fit: cover;
      object-position: center;
      height: 100%;
      width: 100%;
      transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    }
  }

  &:focus,
  &:hover {
    text-decoration: none;

    .promoImageWrapper__title {
      color: $color-terciary;
    }

    img {
      transform: scale(1.03);
      transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
    }
  }

  &__badge {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;

    @include tablet-up {
      top: 17px;
      right: 18px;
    }
  }
}
