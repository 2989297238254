@import "styles/styles.scss";
.FitFinderNotification {
  box-shadow: 0 3px 6px rgb(0 0 0 / 20%);
  background-color: $color-primary-light;
  position: absolute;
  top: 0;
  transform: translateY(0);
  z-index: 4;
  transition: transform .15s ease;
  width: 100%;

  &.FitFinderNotificationVisible {
    transform: translateY($header-height-regular);
    transition: transform .15s ease;

    @include desktop-up {
      transform: translateY($header-height-large);
    }
  }

  &__wrapper {
    position: relative;
  }

  &__container {
    max-width: $max-width;
    margin: 0 auto;
    display: flex;
    align-items: center;
    width: 100%;

    @include desktop-up {
      justify-content: center;
      height: 50px;
    }

    > div:not(.FitFinderNotification__closeButton) {
      width: 100%;
    }
  }

  &__closeButton {
    margin-left: 20px;

    button {
      background-color: $color-primary;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      width: 50px;

      @include desktop-up {
        height: 50px;
        width: 50px;
      }
    }

    @include desktop-up {
      margin: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}
