@import "styles/styles.scss";
.modal {
  align-items: center;
  background: rgb(0 0 0 / 50%);
  bottom: 0;
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1000002;

  @include desktop-up {
    overflow: auto;
  }
}
