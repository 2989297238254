@import "styles/styles.scss";
.flashMsg {
  @include bodyCopyBold;
  @include bodyCopySmallX;

  color: inherit;
  margin-right: 9px;

  @include mobile-small-down {
    font-size: 11px;
  }

  .flashMsg__mobile {
    line-height: 13px;
  }

  .flashMsg__desktop {
    display: none;
  }

  @include desktop-up {
    .flashMsg__desktop {
      display: block;
    }

    .flashMsg__mobile {
      display: none;
    }
  }
}
