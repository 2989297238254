@import "styles/styles.scss";
.SaveFitWrapper {
  height: 100%;

  &__content {
    width: 100%;
    padding: 30px;

    > h2 {
      text-align: center;
    }
  }

  &.noPadding {
    .SaveFitWrapper__content {
      padding: 0;
    }
  }

  &__image {
    img {
      width: 100%;
    }
  }

  &__description {
    text-align: center;
    margin: 15px auto 0;
    max-width: 400px;
  }

  &__card {
    position: relative;
    width: 100%;

    h2 {
      @include titleSmallX2;

      margin-top: 47px;

      &::after {
        display: block;
        content: "";
        width: 100%;
        height: 1px;
        background-color: $color-secondary;
        margin-top: 15px;
        margin-bottom: 15px;
      }
    }

    .SizeHelper {
      position: absolute;
      top: 47px;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 1;
      background-color: $color-neutral-light;
      padding: 20px;
      text-align: center;

      &__closeButton {
        position: absolute;
        top: 15px;
        right: 15px;
      }

      &__contentWrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
      }

      &__contentTitle {
        @include bodyCopyBold;

        margin-bottom: 10px;
      }

      &__content {
        @include bodyCopySmall;

        text-wrap: balance;
      }
    }
  }

  &__cardSize {
    display: flex;
    justify-content: space-between;

    &:last-of-type {
      margin-bottom: 20px;
    }
  }

  &__cardSizeLabel,
  &__cardSizeOption {
    padding: 8px 0;
  }

  &__cardSizeLabel {
    display: flex;
    align-items: center;
  }

  &__dropdownWrapper {
    display: flex;
    align-items: center;
  }

  &__cardSizeOptionPrevious {
    text-decoration: line-through;
    color: $color-terciary-extra-light;
    margin-right: 10px;
  }

  &__buttons {
    width: 100%;
    margin: 40px auto 0;

    button {
      margin-bottom: 20px;
    }
  }

  &:not(&.small) {
    h2 {
      text-align: center;
    }

    @include tablet-up {
      display: flex;
      flex-direction: row-reverse;

      .SaveFitWrapper__image {
        overflow: hidden;
        position: relative;

        img {
          position: relative;
          transform: translateX(-50%);
          left: 50%;
          width: auto;
          height: 100%;
        }
      }

      .SaveFitWrapper__content {
        display: flex;
        flex-direction: column;
        width: fit-content;
        min-width: 600px;
        margin: auto;
      }

      .SaveFitWrapper__cards {
        display: flex;
        justify-content: center;
      }

      .SaveFitWrapper__card {
        width: 230px;

        &:first-child {
          margin-right: 20px;
        }

        &:last-child {
          margin-left: 20px;
        }
      }

      .SaveFitWrapper__buttons {
        display: flex;
        max-width: 400px;

        :first-child {
          margin-right: 10px;
        }

        :last-child {
          margin-left: 10px;
        }
      }
    }
  }
}

.SavedFitCard__helpButton {
  margin-left: 10px;
}
