/** @TODO remove this file completely **/
.grid {
  display: flex;
  justify-content: center;
  flex: 0 0 auto;

  &__container {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    max-width: $max-width;
    width: 100%;
  }

  &__item {
    flex-wrap: nowrap;

    &:not(.grid__item--nogrow) {
      flex-grow: 1;
    }

    &.grid__item--nogrow-tablet-up {
      @include tablet-large-up {
        flex-grow: 0;
      }
    }

    &.grid__item--nogrow-desktop-up {
      @include desktop-up {
        flex-grow: 0;
      }
    }

    &--40 {
      width: 40%;

      &-desktop-up {
        @include desktop-up {
          width: 40%;
        }
      }
    }

    &--45 {
      width: 45%;
    }

    &--50 {
      width: 50%;
    }
  }

  &--column {
    & > .grid__container {
      align-items: stretch;
      flex-direction: column;
    }
  }

  &.grid--full-height {
    height: 100%;

    > .grid__container {
      height: 100%;
    }
  }

  &--gutter {
    & > .grid__container {
      max-width: $max-width + 28px;
      padding-left: 14px;
      padding-right: 14px;

      @include tablet-up {
        max-width: $max-width + 80px;
        padding-left: 40px;
        padding-right: 40px;
      }
    }

    & > .grid__container--large {
      @media (min-width: 1550px) {
        max-width: 1550px;
      }
    }
  }

  &--horizontal-space-between {
    &:not(.grid--column) > .grid__container {
      justify-content: space-between;
    }

    &.grid--column {
      & > .grid__container {
        align-items: space-between;
      }
    }
  }

  &--horizontal-center {
    &:not(.grid--column) > .grid__container {
      justify-content: center;
    }

    &.grid--column {
      & > .grid__container {
        align-items: center;
      }
    }
  }

  &--horizontal-end {
    &:not(.grid--column) > .grid__container {
      justify-content: flex-end;
    }

    &.grid--column {
      & > .grid__container {
        align-items: flex-end;
      }
    }

    &-desktop-up {
      &:not(.grid--column) > .grid__container {
        @include desktop-up {
          justify-content: flex-end;
        }
      }

      &.grid--column {
        & > .grid__container {
          @include desktop-up {
            align-items: flex-end;
          }
        }
      }
    }
  }

  &--vertical-center {
    &:not(.grid--column) > .grid__container {
      align-items: center;
    }

    &.grid--column {
      & > .grid__container {
        justify-content: center;
      }
    }

    &-desktop-up {
      @include desktop-up {
        &:not(.grid--column) > .grid__container {
          align-items: center;
        }

        &.grid--column {
          & > .grid__container {
            justify-content: center;
          }
        }
      }
    }
  }

  &--nowrap {
    & > .grid__container {
      flex-wrap: nowrap;
    }
  }

  &--full-width {
    > .grid__container {
      max-width: 100%;
    }
  }

  &--white {
    background-color: $color-neutral-min;
  }

  &--grey {
    background-color: $color-neutral-light;

    @include tablet-up {
      background-color: $color-neutral-light;
    }
  }

  &--grey-light {
    background-color: $color-neutral-light;
  }

  &--padding-bottom {
    padding-bottom: 60px;
  }
}
