@import "styles/styles.scss";
.HeaderContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  position: sticky;
  top: 0;
  z-index: 1000001;
  backface-visibility: hidden;
  height: $header-height-regular;
  background-color: var(--header-background-theme);
  border-bottom: 1px solid $color-neutral-medium;
  width: 100%;

  @include desktop-up {
    height: $header-height-large;

    &:hover {
      background-color: $color-neutral-min;
      transition: background-color var(--header-transition);
    }
  }

  &__default {
    border-bottom: 1px solid $color-neutral-medium;
  }

  &__transparentLight,
  &__transparentDark {
    transition: background-color var(--header-transition) 0.2s,
      border-bottom var(--header-transition) 0.2s;
    border: none;
  }
}
