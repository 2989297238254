@import "styles/styles.scss";
.gdprFields {
  margin: 0 auto;

  a {
    color: #0a1e2d;
  }

  @include desktop-up {
    margin: unset;
  }
}

.gdprFieldsValidation {
  @include bodyCopySmallX;

  color: $color-success;
  display: none;

  &[data-open] {
    display: block;
    margin-top: 10px;
  }
}

.gdprFieldsNarrow {
  max-width: 430px;
}

.gdprLink {
  text-decoration: underline;
}

.gdprFieldsHidden {
  .gdprText,
  .gdprLink {
    display: none;
  }
}

.gdprTerms {
  @include bodyCopySmallX;

  margin-bottom: 20px;
  margin-top: 18px;
  text-align: center;

  a {
    @include bodyCopySmallX;
  }

  @include desktop-up {
    text-align: left;
  }
}
