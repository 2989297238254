@import "styles/styles.scss";
@keyframes slide-up-menu-link-border {
  0% {
    bottom: -3px;
  }

  100% {
    bottom: 0;
  }
}

.DesktopMenuLink {

  @include fontFamilySecondary();
  
  font-size: 18px;
  color: var(--header-color-theme);
  padding: 0 15px;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 5;
  overflow: hidden;
  position: relative;
  transition: bottom var(--header-transition) 1s, color var(--header-transition);

  &::after {
    content: '';
    display: block;
    width: calc(100% - 30px);
    background-color: $color-secondary;
    height: 3px;
    position: absolute;
    left: 15px;
    transition: bottom var(--header-transition);
    bottom: -3px;
  }

  &:hover {
    text-decoration: none;
    color: $color-primary-extra-light;
    cursor: pointer;

    &::after {
      animation-duration: 0.2s;
      animation-timing-function: ease-in-out;
      animation-name: slide-up-menu-link-border;
      animation-fill-mode: forwards;
      animation-delay: 0.1s;
    }
  }

  &__highlight {
    color: $color-secondary;

    &:hover {
      color: $color-secondary;
    }
  }

  &__active {
    color: $color-primary;

    &::after {
      animation-duration: 0.2s;
      animation-timing-function: ease-in-out;
      animation-name: slide-up-menu-link-border;
      animation-fill-mode: forwards;
      animation-delay: 0.1s;
    }
  }
}