@import "styles/styles.scss";
.DesktopHeader__primaryLinks {
  height: $header-height-large;
  z-index: 5;

  > ul {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    height: 100%;

    > li {
      @extend %list-no-style;
      
      height: 100%;
    }
  }
}