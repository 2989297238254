@import "styles/styles.scss";
.helpButton {
  @include bodyCopySmallX;

  background-color: $color-neutral-min;
  border-radius: 50%;
  height: 20px;
  min-width: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  outline: none;
  cursor: pointer;
  border: 1px solid $color-terciary-extra-light;
  transition-property: background-color, border, color;
  transition-duration: 0.3s;
  transition-timing-function: $ease-in-out;

  &:hover {
    @include desktop-up {
      background-color: $color-neutral-light;
    }
  }

  &.active {
    border: 1px solid $color-primary;
    background-color: $color-primary;
    color: $color-neutral-min;
    font-size: 13px;
  }
}
