@import "styles/styles.scss";
.flashDiscount {
  color: $color-neutral-min;
  align-items: baseline;
  display: flex;
  text-transform: inherit;
}

.flashDiscount_center {
  justify-content: center;
}

.flashDiscount__label {
  @include bodyCopySmallX;
}

.flashDiscount__code {
  @include bodyCopyBold;
  @include bodyCopySmall;

  margin-left: 4px;
}
