@import "styles/styles.scss";
@keyframes slide-up-menu-link-border {
  0% {
    bottom: -3px;
  }

  100% {
    bottom: 0;
  }
}

.DesktopHeader__secondaryLinks {
  margin-left: auto;
  display: flex;
  align-items: center;
  height: $header-height-large;
  padding-right: $header-desktop-padding;
  
  a:not(.DesktopHeader__cartIcon), button:not(.DesktopHeader__cartIcon) {
    margin-right: 30px;
  }
}

.DesktopHeader__accountIcon {
  height: 23px;
  width: 23px;

  path {
    fill: var(--header-color-theme);
    transition: fill 0.3s ease-in;;
  }

  &:hover {
    path {
      fill: $color-primary-extra-light;
    }
  }
}

.DesktopHeader__searchIcon {
  height: 100%;
  padding: 0 6px;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 100%;
    background-color: $color-secondary;
    height: 3px;
    position: absolute;
    left: 0;
    transition: bottom var(--header-transition);
    bottom: -3px;
  }

  &:hover, &_active {
    text-decoration: none;
    color: $color-primary-extra-light;
    cursor: pointer;

    &::after {
      animation-duration: 0.2s;
      animation-timing-function: ease-in-out;
      animation-name: slide-up-menu-link-border;
      animation-fill-mode: forwards;
      animation-delay: 0.1s;
    }

    path, circle {
      stroke: $color-primary-extra-light;
    }
  }

  path, circle {
    stroke: var(--header-color-theme);
    transition: stroke 0.3s ease-in;;
  }
}

.DesktopHeader__cartIcon {
  margin-top: -3px;
}

.fitFinderMenuLink {
  border-color: var(--header-color-theme);
  color: var(--header-color-theme);

  &:hover {
    border-color: $color-primary-extra-light;
    color: $color-primary-extra-light;
  }
}
