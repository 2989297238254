@import "styles/styles.scss";
@mixin headerDesktopLarge {
  @media screen and (min-width: 1440px) {
    @content;
  }
}

.Megamenu {
  max-height: 370px;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  transition: opacity var(--header-transition);

  &__container {
    max-width: $max-width-large;
    padding-left: 20px;
    margin: 0 auto;
    height: 100%;

    @include desktopX1-up {
      padding-left: 125px;
    }
  }
}

.Megamenu__Grid {
  display: flex;

  &_links {
    display: flex;
    gap: 50px;
    min-width: 90px;
    padding-top: 45px;
    margin-right: 90px;

    @include headerDesktopLarge {
      gap: 90px;
      margin-right: 90px;
    }

    &_shrink {
      gap: 40px;

      @include headerDesktopLarge {
        gap: 70px;
      }
    }
  }
}

.Megamenu__GridPromo_featuredImage:not(.promo_hidden) {
    margin-left: 0;

    @media screen and (min-width: 1440px) {
      margin-left: auto;
    }
  }

.Megamenu__Grid_promoImages {
  margin-left: 20px;
  display: flex;
  align-items: flex-start;
  gap: 4px;

  @include desktopX1-up {
    margin-left: 90px;
  }
}