@import "styles/styles.scss";
.flash {
  height: $flashbanner-height-mobile;
  max-height: 200px;
  max-width: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 1000001;

  @include tablet-up {
    height: $flashbanner-height-desktop;
  }
}

.notificationBannerWrapper {
  max-height: 0;
  overflow: hidden;
  position: absolute;
  transition: max-height 0.3s $ease-in-out;
  top: 0;
  z-index: 1000002;
  width: 100%;

  &.showNotificationBanner {
    max-height: 200px;
    position: relative;

    + .flash {
      position: absolute;
    }
  }
}

.notificationHeader {
  padding: 12px;
}

.notification__button {
  align-items: center;
  display: flex;
  height: 100%;
  margin-left: 10px;

  @include desktop-up {
    align-self: center;
    margin-top: 0;
    margin-left: 20px;
  }
}
