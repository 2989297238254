@import "styles/styles.scss";
@import "button-variables";
$animation-speed: 1s;

@keyframes loading {
  0%,
  100% {
    opacity: 0.5;
    transform: scale(0.8);
  }

  30%,
  60% {
    opacity: 1;
    transform: scale(1);
  }
}

.submitButton {
  border-color: $color-primary;
  background-color: $color-primary;
  color: $color-neutral-min;
  overflow: hidden;

  &:focus-visible {
    background-color: $color-primary-light;
    border-color: $color-primary-light;
  }

  .button__content {
    width: 100%;
  }

  .button__loading {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(-100%);
  }

  .button__dot {
    background-color: $color-neutral-min;
    border-radius: 50%;
    display: block;
    height: 10px;
    margin-left: 5px;
    margin-right: 5px;
    opacity: 0;
    transition: background-color 0.3s $ease-in-out;
    width: 10px;
  }

  .button__tick {
    height: 34px;
    position: absolute;
    opacity: 0;
    transform: scale(0);
    transition: opacity 0.3s $ease-in, transform 0.3s $ease-in,
      background-color 0.3s $ease-in-out;
    width: 34px;

    &::before,
    &::after {
      content: "";
      left: 0;
      position: absolute;
      top: 0;
      transition: background-color 0.3s $ease-in-out;
    }

    &.button__tick_success {
      &::before,
      &::after {
        background-color: $color-neutral-min;
        transform: rotate(-45deg);
      }

      &::before {
        height: 3px;
        left: 11px;
        top: 16px;
        width: 19px;
      }

      &::after {
        height: 9px;
        left: 10px;
        top: 16px;
        width: 3px;
      }
    }

    &.button__tick_error {
      &::before,
      &::after {
        background-color: $color-danger;
      }

      &::before {
        height: 3px;
        left: 6px;
        top: 15px;
        width: 22px;
        transform: rotate(-45deg);
      }

      &::after {
        height: 3px;
        left: 6px;
        top: 15px;
        width: 22px;
        transform: rotate(45deg);
      }
    }
  }

  &.ready {
    &:hover {
      background-color: $color-primary-medium;
      border-color: $color-primary-medium;
    }

    @media (hover: none) and (pointer: coarse) {
      &:hover {
        border-color: $color-primary;
        background-color: $color-primary;
      }
    }

    .button__content {
      transition: transform 0.3s 0.3s $ease-out;
    }
  }

  &.loading {
    .button__content {
      transform: translateX(250%);
      transition: transform 0.3s $ease-in;
    }

    .button__loading {
      transform: translateX(0);
    }
  }

  &.success,
  &.error {
    .button__content {
      transform: translateX(250%);
    }

    .button__loading {
      transform: translateX(100%);
      transition: transform 0.3s $ease-in;
    }

    .button__tick {
      transform: scale(1);
      transition: opacity 0.3s 0.3s $ease-out, transform 0.3s 0.3s $ease-out,
        background-color 0.3s $ease-in-out;
    }
  }

  &.success {
    .button__tick_success {
      opacity: 1;
    }
  }

  &.error {
    background-color: $color-terciary-extra-light;
    border-color: $color-terciary-extra-light;

    .button__tick_error {
      opacity: 1;
    }
  }

  &.secondary {
    color: $color-terciary;
    border-color: $color-terciary-light;
    background-color: transparent;

    &:focus-visible,
    &:hover {
      background-color: transparent;
      color: $color-primary;
      border-color: $color-primary;
    }

    @media (hover: none) and (pointer: coarse) {
      &:hover {
        color: $color-terciary;
        border-color: $color-terciary-light;
        background-color: transparent;
      }
    }

    .button__tick.button__tick_success {
      &::before,
      &::after {
        background-color: $color-terciary;
      }
    }

    .button__dot {
      background-color: $color-terciary;
    }
  }

  &.white {
    background-color: $color-neutral-min;
    border-color: $color-neutral-min;
    color: $color-terciary;

    &:focus-visible {
      background-color: $color-neutral-light;
      border-color: $color-neutral-light;
    }

    &:focus-visible,
    &:hover {
      color: $color-primary;
    }

    &:hover {
      background-color: $color-neutral-min;
      border-color: $color-neutral-min;
      color: $color-primary;
    }

    @media (hover: none) and (pointer: coarse) {
      &:hover {
        border-color: $color-neutral-min;
        background-color: $color-neutral-min;
        color: $color-terciary;
      }
    }

    .button__tick.button__tick_success {
      &::before,
      &::after {
        background-color: $color-terciary;
      }
    }

    .button__dot {
      background-color: $color-terciary;
    }
  }

  &.loading,
  &.success,
  &.error {
    cursor: progress;

    .button__dot {
      animation: loading $animation-speed $ease-in-out infinite;

      &:nth-child(1) {
        animation-delay: initial;
      }

      &:nth-child(2) {
        animation-delay: $animation-speed / 10;
      }

      &:nth-child(3) {
        animation-delay: ($animation-speed / 10) * 2;
      }

      &:nth-child(4) {
        animation-delay: ($animation-speed / 10) * 3;
      }

      &:nth-child(5) {
        animation-delay: ($animation-speed / 10) * 4;
      }
    }
  }
}
