@import "styles/styles.scss";
.cartMenuIcon {
  width: 100%;
  text-align: center;
  position: relative;

  svg {
    display: block;

    path {
      fill: var(--header-color-theme);
      transition: fill 0.3s ease-in;;
    }
  }

  &:hover {
    svg path {
      fill: $color-primary-extra-light;
    }
  }

  &::after {
    @include bodyCopyLightBold;
    @include bodyCopySmall;
    
    font-size: 11px;
    content: attr(data-total);
    color: $color-secondary;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 2px;
  }
}
