@import "styles/styles.scss";
.AccordionButton__plus,
.AccordionButton__minus {
  float: right;
}

.accordion__container {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s $ease-in-out;
}

.AccordionButton,
.AccordionButton_textOnly {
  @include subtitleMedium(true);

  padding-bottom: 20px;
  padding-top: 20px;
  text-align: left;
  width: 100%;
  display: block;
}

.AccordionButton {
  cursor: pointer;
  outline: none;
  transition: color 0.3s $ease-in-out;

  &:hover,
  &:focus-visible {
    color: $color-secondary;
  }
}

.accordion__section {
  border-top: 1px solid $color-neutral-light;

  &[data-open="true"] {
    .accordion__container {
      max-height: 1200px;
      transition: max-height 0.5s $ease-in-out 0.2s;
    }

    .AccordionButton__plus {
      display: none;
    }
  }

  &[data-open="false"] {
    .AccordionButton__minus {
      display: none;
    }
  }

  &[data-hidden] {
    display: none;
  }

  &:last-child {
    border-bottom: 1px solid $color-neutral-light;
  }

  &.disabled {
    .AccordionButton__plus,
    .AccordionButton__minus {
      display: none;
    }

    .AccordionButton {
      pointer-events: none;
      cursor: default;
    }

    border: none;
  }
}

.accordion__content {
  padding-bottom: 20px;

  ul {
    max-width: 500px;
    color: $color-terciary;

    &:not(:first-child) {
      margin-top: 15px;
    }
  }

  p {
    color: $color-terciary;
    max-width: 500px;
    margin-top: 15px;
  }

  a {
    color: $color-secondary;
    outline: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}
