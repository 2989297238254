@import "styles/styles.scss";
$animation-timing: cubic-bezier(0.65, 0.05, 0.36, 1);

@keyframes fade-and-slide-out {
  0% {
    opacity: 1;
    left: 0;
    visibility: visible;
  }

  100% {
    visibility: hidden;
    left: -10px;
    opacity: 0;
  }
}

@keyframes fade-and-slide-in {
  0% {
    visibility: hidden;
    left: -30px;
    opacity: 0;
  }

  100% {
    opacity: 1;
    left: 0;
    visibility: visible;
  }
}



.MobileMenuGrid {
  color: $color-primary;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;

  &__active {
    animation-name: fade-and-slide-in;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
    animation-timing-function: $animation-timing;
  }

  &__inactive {
    animation-name: fade-and-slide-out;
    animation-fill-mode: forwards;
    animation-duration: 0.4s;
    animation-timing-function: $animation-timing;
  }
}

.MobileMenuGrid__container {
  padding: 60px 0 30px 30px;
  overflow-y: auto;
  height: 100vh;
  position: relative;
}