@import "styles/styles.scss";
.headerLogo {
  margin-right: auto;
  align-items: center;
  height: $header-height-regular;
  display: flex;
  position: relative;
  overflow: hidden;
  margin-bottom: -1px;

  @include desktop-up {
    margin-right: 0;
    height: $header-height-large;
    padding: 0 $header-desktop-padding;
    width: 110px;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 44px;
    height: 3px;
    background-color: $color-secondary;
    transition: bottom 0.3s ease-in-out;

    @include desktop-up {
      width: 61px;
    }
  }

  svg {
    height: 25px;
    width: 42px;

    @include desktop-up {
      height: 52px;
      width: 61px;
    }


    path:first-of-type {
      fill: var(--header-color-theme);
      transition: fill var(--header-transition);
    }
  }

  &__xmas {

    @include desktop-up {
      width: 115px;
    }

    &::after {
      width: 46px;

      @include desktop-up {
        width: 68px;
      }
    }

    svg {
      height: 37px;
      width: 46px;
  
      @include desktop-up {
        height: 35px;
        width: 68px;
      }
  
  
      path:first-of-type {
        fill: var(--header-color-theme);
        transition: fill var(--header-transition);
      }
    }
  }

  &:hover,
  &:focus {
    svg path {
      fill: $color-secondary;
      transition: fill var(--header-transition);
    }

    &::after {
      bottom: 0;
    }
  }
}
