@import "styles/styles.scss";
$overlay-background-color: rgb(0 0 0 / 50%);
$cubic-bezier: cubic-bezier(0.56, 0.01, 0.62, 0.92);

.drawerOverlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000002;
  width: 100%;
  height: 100%;
  background-color: transparent;
  transition: background-color 0.3s $ease-in-out;
}

.drawer {
  background: $color-neutral-min;
  width: 100%;
  position: absolute;
  z-index: 3;
  transition: transform 0.4s $cubic-bezier;

  &.size_small {
    max-width: 375px;
  }

  &.size_medium {
    max-width: 425px;
  }

  &.origin_right {
    right: 0;
    top: 0;
    height: 100%;
    transform: translateX(100%);

    &.size_auto {
      max-width: 100%;
      width: auto;
      height: 100%;
    }
  }

  &.origin_bottom {
    right: 0;
    left: 0;
    bottom: 0;
    transform: translateY(100%);

    &.size_auto {
      max-width: 100%;
      height: auto;
    }
  }
}

.enter {
  .drawerOverlay {
    background-color: transparent;

    .drawer {
      &.origin_right {
        transform: translateX(100%);
      }

      &.origin_bottom {
        transform: translateY(100%);
      }
    }
  }
}

.enterActive,
.enterDone {
  .drawerOverlay {
    background-color: $overlay-background-color;

    .drawer {
      &.origin_right {
        transform: translateX(0);
      }

      &.origin_bottom {
        transform: translateY(0);
      }
    }
  }
}

.exit {
  .drawerOverlay {
    background-color: $overlay-background-color;

    .drawer {
      &.origin_right {
        transform: translateX(0);
      }

      &.origin_bottom {
        transform: translateY(0);
      }
    }
  }
}

.exitActive,
.exitDone {
  .drawerOverlay {
    background-color: transparent;

    .drawer {
      &.origin_right {
        transform: translateX(100%);
      }

      &.origin_bottom {
        transform: translateY(100%);
      }
    }
  }
}
