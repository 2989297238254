@import "styles/styles.scss";
/* stylelint-disable color-function-notation */
.notification {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 40px 20px 20px;
  background: $color-neutral-light;
  color: $color-terciary-light;
  position: relative;

  @include tablet-up {
    padding: 15px 40px 15px 20px;
  }

  a {
    @include bodyCopyBold;

    text-decoration: underline;
  }

  p,
  p a {
    @include bodyCopySmall;

    text-align: center;
  }

  &.success {
    background-color: #f3f8f3;
    color: $color-success;
  }

  &.info {
    background-color: rgba(#98c1df, 0.1);
    color: $color-primary-extra-light;
  }

  &.warning {
    background-color: #fff1e3;
    color: $color-warning;
  }

  &.error {
    background-color: #fdf4f5;
    color: $color-danger;

    a {
      color: $color-neutral-min;
    }
  }

  &.alert {
    p {
      @include bodyCopySmall;
    }
  }

  &.notification_dismissible {
    width: 100%;
  }

  .notification__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
  }

  &.dark {
    color: $color-neutral-min;
    background-color: $color-terciary-light;

    svg {
      fill: $color-neutral-min;
    }

    &.success {
      background-color: $color-success;
    }

    &.info {
      background-color: $color-primary-extra-light;
    }

    &.warning {
      background-color: $color-warning;
    }

    &.error {
      background-color: $color-danger;
    }
  }
}
