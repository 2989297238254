@import "styles/styles.scss";
.featuredImage {
  background-color: $color-neutral-medium;
  position: relative;
  width: var(--width);
  height: var(--height);

  &__image {
    height: 100%;
    width: 100%;
    overflow: hidden;

    img {
      object-fit: cover;
      object-position: center;
      height: 100%;
      width: 100%;
      transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    }
  }

  &:hover {
    > * {
      text-decoration: none
    }

    img {
      transform: scale(1.03);
      transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
    }
  }

  &__Title {
    display: none;

    @include desktop-up {
      @include titleSmallX2;

      display: block;
      position: absolute;
      color: $color-neutral-min;
      top: 17px;
      left: 12px;
      z-index: 1;
      margin: 0;
      writing-mode: tb-rl;
      transform: rotate(-180deg);
    }
  }

  &__imageButton {
    display: none;
    position: absolute;
    bottom: 21px;
    left: 21px;
    z-index: 1;
    width: max-content;

    @include desktop-up {
      display: block;
    }
  }

  &__badge {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;

    @include tablet-up {
      top: 17px;
      right: 18px;
    }
  }
}

.mobileTitle {
  @include titleSmallX4;

  margin-top: 13px; 

  @include desktop-up {
    @include titleSmallX2(true);

    display: none;
  }

  &__textAlignCenter {
    padding-left: 30px;
  }
}
