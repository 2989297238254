@import "styles/styles.scss";
.FooterLinksColumns__title {
  width: 100%;

  button,
  span {
    @include subtitleMedium;

    color: $color-neutral-min;
  }

  span {
    padding-top: 0;
  }

  button {
    @include subtitleSmall;

    &:hover {
      color: $color-neutral-min;
    }
  }
}

.FooterLinksColumns__list {
  @extend %list-no-style;

  a {
    @include bodyCopySmall;

    color: $color-neutral-min;
  }
}

.FooterLinksColumns__listItem {
  margin-bottom: 10px;
}

.FooterLinksColumns__itemLink {
  color: $color-neutral-min;
  outline: none;
  white-space: nowrap;
}

.FooterLinksColumns {
  width: 100%;
  margin-top: 40px;

  @include tablet-up {
    width: 60%;
    margin-top: 0;
  }

  @include desktop-up {
    display: flex;
    justify-content: flex-end;
    justify-content: space-evenly;
  }
}

.FooterLinksColumns__column {
  border-top-color: $color-terciary;

  &:last-child {
    margin-right: 0;
    border-bottom-color: $color-terciary;
  }

  @include desktop-up {
    margin-left: auto;
    margin-right: 25px;
    margin-top: 5px;
  }
}
