@import "styles/styles.scss";
.badge {
  @include bodyCopySmallX;
  @include bodyCopyBold;

  color: $color-neutral-min;
  display: inline-flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 8px 13px;
  white-space: nowrap;
  border-radius: 2px;

  svg {
    height: 16px;
  }

  &.badge_medium {
    @include bodyCopySmall;

    padding: 5px 9px;
  }

  &.badge_small {
    padding: 3px 7px;
  }

  &.badge_fullWidth {
    width: 100%;
  }

  &.badge_beta {
    margin-left: 10px;

    svg {
      height: 19px;
    }
  }

  &.badge_minimal {
    @include fontFamilyPrimary;

    font-weight: 500;
  }

  .badge__icon {
    margin-right: 5px;
    line-height: 0;
  }

  .badge__iconOnly {
    margin: 0;
  }

  .badge__iconSmall svg {
    height: 13px;
  }
}
