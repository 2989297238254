@import "styles/styles.scss";
.button {
  @include fontFamilyPrimary;
  @include bodyCopyBold;

  color: inherit;
  transition-property: background-color, border, color, text-decoration;
  transition-duration: 0.3s;
  transition-timing-function: $ease-in-out;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 2px;
  align-items: center;
  justify-content: center;
  outline: none;
  position: relative;
  text-align: center;
  max-width: 100%;
  cursor: pointer;

  &:focus-visible {
    border-color: $color-primary;
  }

  &:not(&.neverFullWidth):not(&.inherit) {
    width: 100%;
  }

  @include tablet-up {
    &:not(&.alwaysFullWidth):not(&.inherit) {
      width: auto;
    }
  }
}

.textSize_large {
  @include bodyCopyRegular;
}

.textSize_small {
  @include bodyCopySmall;
}

.large {
  padding: 16px 40px;
}

.small {
  padding: 9px 18px;
}

button.button,
a.button {
  &[disabled] {
    &,
    &:hover,
    &:focus-visible {
      background-color: $color-neutral-medium;
      border-color: $color-neutral-medium;
      color: $color-terciary-extra-light;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
}

button.button {
  display: flex;
}

a.button {
  display: inline-flex;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}