@import "styles/styles.scss";
@import "../styles/core/grid/grid";

body,
html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

main {
  @include bodyCopyRegular(true)
}

input,
body {
  background-color: $color-neutral-min;
  color: $color-terciary;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

a {
  @include bodyCopyRegular;

  color: $color-primary;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

strong,
b {
  font-weight: 600;
}

em {
  font-style: italic;
}

small {
  font-size: 90%;
}

h1,
h2,
h3,
h4,
h5,
h6,
ol,
p,
ul {
  margin-bottom: 0;
  margin-top: 0;
}

h1,
.h1 {
  @include titleLarge(true);
}

h2,
.h2 {
  @include titleSmall(true);

  @include desktop-up {
    @include titleMedium;
  }
}

h3,
.h3 {
  @include titleSmall(true);
}

h4,
.h4 {
  @include titleSmallX1(true);
}

h5,
.h5 {
  @include titleSmallX2(true);
}

input,
input[type="text"],
input[type="email"] {
  appearance: none !important;
}

button {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  color: inherit;
  font-family: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  font-size: inherit;
  line-height: inherit;
}

/** Copy specific color changes (from Contentful data) **/

.inline-copy-orange {
  display: inline-block;
  color: $color-secondary;
}

.inline-copy-blue {
  display: inline-block;
  color: $color-primary;
}

/** AddSearch Custom Styles **/

.ProductList {
  padding: 0;
  margin: 0;
}

.DefaultProductItem {
  @include placeholder-ratio(400, 400);
  @extend %list-no-style;

  background-color: $color-neutral-light;
  width: 100%;
}

.ProductItem {
  @extend %list-no-style;

  padding: 0;
}

.ProductItem a {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  outline: none;
  position: relative;
  width: 100%;
  overflow: hidden;
  transition: border .3s cubic-bezier(.645,.045,.355,1);
}

.ProductItem_imagePlaceholder {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background-color: #eaeaea;
  background-size: cover;
}

.ProductItem__image, .ProductItem__secondaryImage, .ProductItem__mainImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.ProductItem__secondaryImage {
  opacity: 0;
}

.ProductItem__primaryImage, .ProductItem__secondaryImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.ProductItem a:hover {
  text-decoration: none;

  .ProductItem__secondaryImage {
    opacity: 1;
    transition: opacity .3s cubic-bezier(.645,.045,.355,1);
  }
}

.ProductDetails__mainDetails {
  display: flex;
  justify-content: space-between;
}

.ProductDetails {
  padding: 10px 0 0;
}

.ProductDetails__color, .ProductDetails__productType {
  @include bodyCopySmall(true);

  @include desktop-up {
    @include bodyCopyRegular(true);
  }
}

.ProductDetails__productType {
  color: $color-terciary;
}

.ProductDetails__color {
  @include bodyCopyBold;

  padding-top: 0;
}

.ProductDetails__prices {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  flex: 1;
}

.ProductDetails__prices p {
  @include bodyCopyRegular;

  margin: 0;
  color: $color-primary;

  @include bodyCopySmall(true);

  @include desktop-up {
    @include bodyCopyRegular;
  }

  @include bodyCopyBold;
}

.ProductDetails__prices .ProductDetails__fullPrice {
  color: $color-terciary-extra-light;
  text-decoration: line-through;
  margin-right: 5px;
}

.number-of-results {
  @include bodyCopySmall;

  color: $color-terciary-light;
  margin: 20px 0;

  h2 {
    @include titleSmallX3();
    
  }
}

.ProductDetails__fullPrice.hidden {
  display: none;
}

.ProductItem__tag {
  position: absolute;
  top: 0;
  left: 0;

  @include bodyCopySmallX;
  @include bodyCopyBold;

  background-color: $color-secondary;
  color: $color-neutral-min;
  display: inline-flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 3px 7px;
  white-space: nowrap;
  border-radius: 2px;
  z-index: 1;
}

.addsearch-searchresults-no-results {
  em {
    font-style: italic;
    @include bodyCopyRegular;
  }
  a { 
    @include bodyCopyRegular;
    @include bodyCopyBold;

    color: $color-primary;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
  
    &::after {
      content: "";
      display: block;
      width: 99%;
      height: 1px;
      background-color: $color-primary;
      transition-property: color, text-decoration, background-color;
      transition-duration: 0.3s;
      transition-timing-function: $ease-in-out;
    }
  }
}

.dixa-messenger-wrapper {
  z-index: 1000001 !important;
}