@import "styles/styles.scss";
.PrimaryLinksContainer, .SecondaryLinksContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 0;

  @include desktop-up {
    gap: 10px;
  }

  li {
    @extend %list-no-style;
  }
}

.SecondaryLinksContainer {
  gap: 11px;

  @include desktop-up {
    gap: 8px;
  }
  
  h3 {
    @include subtitleSmall(true);

    font-weight: 400;
    margin-bottom: 5px;
    color: $color-terciary-light;

    @include desktop-up {
      margin-bottom: 10px;
    margin-top: 5px;
    }
  }
}