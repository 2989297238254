@import "styles/styles.scss";
.flashContent {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;

  @include tablet-large-down {
    flex: 1;
  }
}

.flashContent__dismissible {
  @include tablet-large-down {
    flex: none;
  }
}

.flashContent_center {
  text-align: center;
  justify-content: center;
}
