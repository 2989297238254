@import "styles/styles.scss";
.flashSvg {
  align-items: center;
  pointer-events: none;
  margin-right: 12px;
  display: flex;
  max-height: 20px;

  &:not(.flashSvg_visible) {
    display: none;

    @include tablet-up {
      display: flex;
    }
  }
}

.flashSvg_visible {
  display: flex;
  
  @include tablet-up {
    display: none;
  }
}

.Icon_secondaryFill {
  fill: $color-secondary;
}

.Icon_primaryFill {
  fill: $color-primary;
}

.Icon_whiteFill {
  fill: $color-neutral-min;
}
