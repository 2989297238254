@import "styles/styles.scss";
.FitSelector {
  @include bodyCopyRegular(true);

  overflow: initial;
  position: relative;
  height: 38px;

  ::-webkit-scrollbar {
    appearance: none;
    width: 6px;
    height: 84px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $color-terciary;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  select,
  &__selectButton {
    @include bodyCopyRegular(true);

    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    padding: 8px 0;
  }

  select {
    color: $color-terciary;
    background-color: transparent;
    appearance: none;
    border: none;
    border-radius: 0;
    margin: 0;
    padding: 0;
    position: relative;
    cursor: pointer;
    text-align: right;
    text-align-last: right; /* necessary for Safari to text align right */
    width: 100%;
    white-space: nowrap;
    flex: 1;
    pointer-events: none;
    outline: none;
  }

  &__selectButton {
    overflow: hidden;

    .arrowDown {
      pointer-events: none;
      margin-left: 10px;
    }

    select,
    p,
    .arrowDown {
      transform: translateX(15px);
      transition: transform 0.2s;
      transition-timing-function: linear;
    }

    &.isEditable {
      select,
      p,
      .arrowDown {
        transform: translateX(0);
      }

      select {
        pointer-events: all;
      }
    }

    &.isOpen {
      color: $color-primary;
    }
  }

  &__selected {
    cursor: pointer;
    text-align: left;
    width: auto;
    -webkit-tap-highlight-color: transparent;
  }

  &__previouslySelectedValue {
    @include bodyCopyRegular;

    text-decoration: line-through;
    color: $color-terciary-extra-light;
    margin-right: 10px;
  }
}
