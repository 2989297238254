@import "styles/styles.scss";
@import "button-variables";

.default {
  &.selected {
    border-color: $color-primary;
  }
}

.primary {
  border-color: $color-primary;
  background-color: $color-primary;
  color: $color-neutral-min;

  &:focus-visible,
  &.selected {
    background-color: $color-primary-light;
    border-color: $color-primary-light;
  }

  &:hover {
    background-color: $color-primary-medium;
    border-color: $color-primary-medium;
  }

  @media (hover: none) and (pointer: coarse) {
    &:hover {
      background-color: $color-primary;
      border-color: $color-primary;
    }
  }

  svg {
    fill: $color-neutral-min;
  }
}

.secondary {
  color: $color-terciary;
  border-color: $color-terciary-extra-light;

  &:focus-visible,
  &.selected {
    background-color: $color-neutral-light;
    color: $color-primary;
    border-color: $color-primary;
  }

  &:focus-visible,
  &:hover {
    color: $color-primary;
    border-color: $color-terciary;
  }

  @media (hover: none) and (pointer: coarse) {
    &:hover {
      color: $color-terciary;
      border-color: $color-terciary-light;
    }
  }
}

.delete {
  color: $color-danger;
  border-color: $color-terciary-light;

  &:focus-visible,
  &.selected {
    border-color: $color-danger;
  }

  &:focus-visible,
  &:hover {
    border-color: $color-terciary;
  }

  @media (hover: none) and (pointer: coarse) {
    &:hover {
      color: $color-danger;
      border-color: $color-terciary-light;
    }
  }
}

.white {
  background-color: $color-neutral-light;
  border-color: $color-neutral-light;
  color: $color-primary;

  &:hover,
  &:focus-visible,
  &.selected {
    border-color: $color-neutral-light;
    color: $color-primary;
  }

  @media (hover: none) and (pointer: coarse) {
    &:hover {
      border-color: $color-neutral-light;
      color: $color-primary;
    }
  }
}

.white_outline {
  border-color: $color-neutral-min;
  color: $color-neutral-min;

  &:focus-visible,
  &.selected {
    background-color: $color-neutral-min;
    color: $color-primary;
  }

  @media (hover: none) and (pointer: coarse) {
    &:hover {
      border-color: $color-neutral-min;
      color: $color-neutral-min;
    }
  }
}

.button_link {
  color: $color-terciary-light;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;

  &::after {
    content: "";
    display: block;
    width: 99%;
    height: 1px;
    background: $color-terciary-light;
    transition-property: color, text-decoration, background-color;
    transition-duration: 0.3s;
    transition-timing-function: $ease-in-out;
  }

  &:hover,
  &:focus {
    color: $color-terciary;

    &::after {
      background: $color-terciary;
    }
  }
}

.link {
  color: $color-terciary-light;
  display: inline-block !important; // Needs to refine this
  text-decoration: none;
  cursor: pointer;

  &::after {
    content: "";
    display: block;
    width: 99%;
    height: 1px;
    background: $color-terciary-light;
    transition-property: color, text-decoration, background-color;
    transition-duration: 0.3s;
    transition-timing-function: $ease-in-out;
  }

  &:hover,
  &:focus {
    color: $color-terciary;

    &::after {
      background: $color-terciary;
    }
  }
}
