@import "styles/styles.scss";
.buttonMinimal {
  @include bodyCopyBold;

  display: inline-block;
  cursor: pointer;

  &:hover,
  &:focus {
    color: $color-terciary;
  }

  &.underlined {
    &::after {
      content: "";
      display: block;
      width: 99%;
      height: 1px;
      background-color: $color-primary;
      transition-property: color, text-decoration, background-color;
      transition-duration: 0.3s;
      transition-timing-function: $ease-in-out;
    }
  }

  &.primary {
    color: $color-primary;
  }

  &.secondary {
    color: $color-terciary;

    &:hover,
    &:focus {
      color: $color-primary;
    }

    &.underlined::after {
      background-color: $color-terciary;
    }
  }

  &.light {
    color: $color-neutral-min;

    &:hover,
    &:focus {
      color: $color-neutral-medium;
    }

    &.underlined::after {
      background-color: $color-neutral-min;
    }
  }

  &.textSize_small {
    @include bodyCopySmallX;
  }

  &.textSize_regular {
    @include bodyCopySmall;
  }

  &.textSize_large {
    @include bodyCopyRegular;
  }

  
}
