@import "styles/styles.scss";

.Megamenu__Grid {
  display: flex;
  max-width: min(93vw, 425px);

  &__featuredImage {
    top: 95px;
    margin-left: auto;
    position: sticky;
    width: auto;
    max-height: 190px;
  }
}

.title {
  @include titleSmallX2(true);

  &::after {
    margin-top: 18px;
    background-color: $color-secondary;
    content: "";
    display: block;
    height: 3px;
    width: 60px;
  }
}

.links {
  max-width: 200px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.backButton {
  position: absolute;
  top: 13px;
  left: 10px;
  line-height: 0;
  padding: 10px 20px;
}

.promoImageGrid {
  display: grid;
  grid-template-columns: 2fr auto;
  max-width: min(100vw, 425px);
  gap: 4px;
  margin-top: 45px;
  margin-left: -30px;

}

.promoLinks {
  @extend .links;

  max-width: 100%;
  margin-top: 48px;
}
