@import "styles/styles.scss";
.footer {
  background-color: $color-primary;
  color: $color-neutral-min;
  padding: 41px 30px 50px;
  position: relative;

  @include tablet-up {
    padding-left: 40px;
    padding-right: 40px;

    .footerBase,
    .footerLinks {
      margin-left: auto;
      margin-right: auto;
      max-width: 1250px;
    }
  }

  @include desktop-up {
    padding-bottom: 63px;
    padding-top: 80px;
  }
}

.footerBase {
  display: block;
  margin-top: 40px;

  @include desktop-up {
    margin-top: 120px;
  }
}

.footerLinks {
  align-items: center;
  display: flex;
  flex-direction: column;

  @include tablet-up {
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
  }
}

.footerBaseContent {
  align-items: center;
  display: flex;
  flex-direction: column;

  @include desktop-up {
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: row;
  }
}

.footerSocialContent {
  order: 1;

  @include desktop-up {
    order: inherit;
    width: 38%;
  }
}

.trustbadge {
  position: fixed;
  z-index: 10000;
  bottom: 15px;
  right: 15px;
  height: 60px;
  width: 60px;
}
