@import "styles/styles.scss";
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .wrapper__content {
    background-color: $color-neutral-min;
    flex-grow: 1;
    flex: 0 0 auto;
    min-height: 70vh;
    display: block;

    @include desktop-up {
      margin-top: 0;
    }

    &.wrapper__content_minHeight {
      min-height: 0;
    }

    &.wrapper__content_noMargin {
      margin-top: 0;
    }
  }
}

.top {
  display: flex;
  flex-direction: column;

  .top__flashes {
    flex-grow: 0;
    flex-shrink: 0;
    overflow: hidden;
    z-index: 16;
  }

  &.top_fullScreen {
    height: calc(100vh - 50px);

    @media (orientation: landscape) {
      height: auto;
    }

    @include desktop-up {
      height: 100vh;
    }

    @include tablet-only-portrait {
      height: 70vh;
    }

    @media (max-aspect-ratio: 2048/1109) and (min-width: 1000px) {
      height: auto;
    }

    &-max {
      max-height: 1000px;

      @include desktopX1-up {
        max-height: 1200px;
      }

      @include desktopX3-up {
        max-height: none;
      }
    }
  }

  &.top_80FullScreen {
    height: 80vh;
  }
}

.AustralianModal {
  color: $color-primary;
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: center;

  @include desktop-up {
    max-width: 350px;
  }

  h1 {
    margin-bottom: 30px;
  }

  p {
    margin-bottom: 15px;
  }

  &__byline {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-left: 10px;
    }
  }

  button {
    margin: 40px auto 0;
  }
}
