@import "styles/styles.scss";
.socialLinkWrapper {
  @extend %list-no-style;

  display: flex;
  text-align: center;
  margin: 20px auto 30px;
  justify-content: center;


  @include desktop-up {
    margin: 0 0 30px;
    justify-content: flex-start;
  }
}

.socialLinkWrapper_light {
  svg {
    path {
      fill: $color-neutral-light;
    }
  }
}

.footerSmallprintFirst {
  @include bodyCopySmall;

  margin-bottom: 20px;
  

  @include desktop-up {
    margin-bottom: 0;
  }

  a {
    @include bodyCopySmall;

    color: $color-neutral-min;
  }

  sup {
    vertical-align: top;
    line-height: 12px;
    font-size: 8px;
    letter-spacing: normal;
  }
}

.footerSmallprintCopyright {
  span::after {
    content: "";
    text-align: center;

    @include tablet-up {
      content: "/";
    }
  }
}
